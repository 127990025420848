export const defaultLdJson = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Huecalm',
  url: 'https://huecalm.eu',
  telephone: '+48 33 445 42 45',
  email: 'info@huecalm.eu',
  areaServed: ['PL', 'EN'],
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+48 33 445 42 45',
    contactType: 'customer service',
    availableLanguage: {
      '@type': 'Language',
      name: ['English', 'Polish'],
      alternateName: ['en', 'pl'],
    },
  },
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Bielsko-Biała',
    postalCode: '43-300',
    streetAddress: 'Bystrzańska 70',
  },

  sameAs: [
    'https://www.facebook.com/huecalm/',
    'https://www.instagram.com/huecalm/',
  ],
};

export const colors = {
  greyLight: 'rgb(138,139,133)',
  grey: 'rgb(102,97,91)',
  greyDark: 'rgb(89,90,82)',
  redLight: 'rgb(234,89,92)',

  white: 'hsl(0, 0%, 100%)',
  gray: 'hsl(0, 0%, 90%)',
  black: 'hsl(0, 0%, 18%)',
  purple: 'hsl(259, 47%, 30%)',
  green: 'hsl(147, 95%, 31%)',
  red: 'hsl(358, 100%, 67%)',
  pink: 'hsl(325, 88%, 68%)',
  yellow: 'hsla(66, 99%, 66%, 1)',
};

export const breakpoints = {
  xs: 320,
  sm: 400,
  smm: 500,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1800,
  xxxxl: 2200,
};

export const mq = Object.keys(breakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
  return acc;
}, {});

export const theme = {
  colors,
  mq,
};

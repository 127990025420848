import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { defaultLdJson } from '../../static/structuredData';

function SEO({ description, meta, title, image, imgAlt }) {
  const metaDescription = description || '';
  const metaTitle = title ? `Huecalm - ${title}` : 'Huecalm';

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <Helmet
          htmlAttributes={{
            lang: currentLocale,
          }}
          title={metaTitle}
          titleTemplate={metaTitle}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: metaTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:image`,
              content: image,
            },
            {
              property: `og:image:alt`,
              content: imgAlt,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:locale`,
              content: currentLocale,
            },
            {
              name: `og:url`,
              content: 'https://www.huecalm.eu/',
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: 'Brainforge IT',
            },
            {
              name: `twitter:title`,
              content: metaTitle,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `twitter:image`,
              content: image,
            },
            {
              name: `twitter:image:alt`,
              content: imgAlt,
            },
          ].concat(meta)}
        >
          <script type="application/ld+json">
            {JSON.stringify(defaultLdJson)}
          </script>
          <script>
            {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '581865114155428');
              fbq('track', 'PageView');`}
          </script>
          <noscript>
            {`<img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=581865114155428&ev=PageView&noscript=1"
            />`}
          </noscript>
        </Helmet>
      )}
    </IntlContextConsumer>
  );
}

SEO.defaultProps = {
  meta: [],
  description: '',
  image: '',
  imgAlt: '',
  title: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  imgAlt: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
